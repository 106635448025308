
$(function() {
    $("body").on("click", ".showgemeindegruss", function() {
        var id = $(this).data("gemeindegruss-id");
        if (!id) {
            showActiveGemeindegruss();
        } else {
            showGemeindegruss(id);
        }
    });
});

function showGemeindegrussId(id) {
    $.ajax({
        url: "api/gemeindegruss/get",
        type: "POST",
        data: {
            id: id
        },
        success: function(resp) {
            showGemeindegruss(resp.gemeindegruss);
        },
        error: function() {
            new Notification().error("Gemeindegruß konnte nicht geladen werden");
        }
    });    
}

function showActiveGemeindegruss() {
    $.ajax({
        url: "api/gemeindegruss/getactive",
        type: "POST",
        success: function(resp) {
            showGemeindegruss(resp.gemeindegruss);
        },
        error: function() {
            new Notification().error("Gemeindegruß konnte nicht geladen werden");
        }
    });
}

function showGemeindegruss(gg) {
    if (!gg) {
        new Notification().info("Der Gemeindegruß ist nicht verfügbar");
        return;
    }
    
    pages = gg.pages;
    openFlipbook(pages, gg.ratio);
}