
class FlipBookViewer {
    static globalFlipBookViewerId = 0;

    constructor(flipBookId = false) {
        this.flipBookId = flipBookId;
        this.flipbook = null;
        this.viewerId = FlipBookViewer.globalFlipBookViewerId++;

        this.animationSpeed = 200;

        // Cache
        this.pdf = null;
        this.pages = {};
        this.pageInfo = {};
        this.maxPages = 0;
        this.numPapers = 0;
        this.canvases = {};

        this.renderTasks = [];
        this.hammer = null;
        this.points = {};

        // Dimensions
        this.singlePageRatio = 1;   // Width / Height
        this.pageWidth = 0;
        this.pageHeight = 0;
        this.maxPageWidth = 0;
        this.maxPageHeight = 0;
        this.singleColumnMode = false;
        this.zoom = 1;
        this.overScale = 2;

        this.pinchData = {
            startZoom: null,
            startPosition: null,
            lastDeltaX: 0,
            lastDeltaY: 0,
            lastDistance: 0
        };

        this.overlay = null;
        this.viewer = null;
        this.controls = null;
        this.book = null;
        this.loader = null;

        this.keydown = this.onKeyDown.bind(this);

        this.currentPaper = 1;
        this.currentPage = 1;


        // Map logical page numbers to pdf pages
        this.pageNumbers = {};

        window.addEventListener("resize", this.onWindowSizeChange.bind(this));
    }

    async loadPdf(pdfUrl) {
        // TODO: Caching
        let loadingTask = pdfjsLib.getDocument(pdfUrl);
        this.pdf = await loadingTask.promise;
        this.maxPages = this.pdf.numPages;
        // Load pages and add meta information
        this.pages = {};
        this.pageInfo = {};
        this.singlePageRatio = null;
        let currentPageNumber = 1;
        for (let i = 1; i <= this.maxPages; i++) {
            let page = await this.pdf.getPage(i);
            this.pages[i] = page;            
            
            let viewport = page.getViewport({"scale": 1});
            let isDoublePage = viewport.width > viewport.height;
            let originalRatio = viewport.width / viewport.height;
            let singlePageRatio = isDoublePage ? originalRatio / 2 : originalRatio;
            if (this.singlePageRatio === null) {
                this.singlePageRatio = singlePageRatio;
            } else {
                console.warn(`Inconsistent single page ratios: ${this.singlePageRatio} vs ${singlePageRatio}`);
                this.singlePageRatio = singlePageRatio;
            }

            this.pageInfo[i] = {
                "isDoublePage": isDoublePage,
                "pageNumber": currentPageNumber,
                "ratio": singlePageRatio,
                "originalRatio": originalRatio
            };
            if (isDoublePage) {
                this.pageInfo[i]["pageNumberLeft"] = currentPageNumber;
                this.pageInfo[i]["pageNumberRight"] = currentPageNumber + 1;
                currentPageNumber += 2;                
            } else {
                currentPageNumber += 1;
            }
        }
        return this.pdf;
    }

    async getFlipBook(flipBookId) {
        return this.loadFlipBook(flipBookId);
    }

    getPaperId(pageNum) {
        return Math.floor((pageNum + 1) / 2);
    }

    getPaperDiv(pageNum) {
        let paperId = this.getPaperId(pageNum);
        let paper = this.book.find(`.flipbook-paper[data-paperid="${paperId}"]`);
        if (paper.length === 0) {
            paper = $(`<div class="flipbook-paper" data-paperid="${paperId}"></div>`);
            this.book.append(paper);
        }
        return paper;
    }

    getPaperDivByPaperId(paperId) {
        return this.book.find(`.flipbook-paper[data-paperid="${paperId}"]`);
    }

    onWindowSizeChange() {
        this.calculateSize();
        this.updateSize();
        this.render();
    }

    calculateSize() {
        let viewerWidth = this.viewer.width();
        let viewerHeight = this.viewer.height();
        this.book[0].style.setProperty("--parentWidth", `${this.viewer.width()}px`);
        this.book[0].style.setProperty("--parentHeight", `${this.viewer.height()}px`);
        this.maxPageWidth = viewerWidth * 0.9;
        this.maxPageHeight = viewerHeight * 0.9;
        
        this.singleColumnMode = viewerHeight > viewerWidth;
        
        let maxRatio = this.maxPageWidth / this.maxPageHeight;
        if (maxRatio > this.singlePageRatio) {
            // Actual height is limiting factor
            this.pageHeight = this.maxPageHeight;
            this.pageWidth = this.maxPageHeight * this.singlePageRatio;
        } else {
            // Actual width is limiting factor
            this.pageWidth = this.maxPageWidth;
            this.pageHeight = this.maxPageWidth / this.singlePageRatio;
        }
    }

    updateSize() {
        let bookWidth = Math.floor(this.pageWidth * 2 * this.overScale); // * this.zoom;
        let bookHeight = Math.floor(this.pageHeight * this.overScale); // * this.zoom;
        let canvasWidth = Math.floor(this.pageWidth * this.overScale); // * this.zoom;
        let canvasHeight = Math.floor(this.pageHeight *  this.overScale); // * this.zoom;

        this.book[0].style.setProperty("--width", `${bookWidth}px`);
        this.book[0].style.setProperty("--height", `${bookHeight}px`);
        let outputScale = window.devicePixelRatio || 1;

        this.book.find(".flipbook-paper").css({"width": `${canvasWidth}px`, "height": `${canvasHeight}px`});

        for (let canvasId in this.canvases) {
            let canvas = this.canvases[canvasId];
            canvas.css("width", `${canvasWidth}px`);
            canvas.css("height", `${canvasHeight}px`);        
            canvas.attr("width", Math.floor(canvasWidth * outputScale));
            canvas.attr("height", Math.floor(canvasHeight * outputScale));
        }
        
        this.updateZoom();
    }

    getViewerCenterPoint() {
        return {
            x: this.viewer.width() / 2,
            y: this.viewer.height() / 2,
        };
    }

    getBookPointAtCenter() {
        let leftPosition = this.book.position().left;
        let topPosition = this.book.position().top;
        return {
            x: this.viewer.width() / 2 - leftPosition,
            y: this.viewer.height() / 2 - topPosition,
        };
    }

    getBookCenterPoint() {
        return {
            x: this.book.position().left + this.book.width() / 2,
            y: this.book.position().top + this.book.height() / 2
        };
    }

    getMovePosition() {
        return {
            left: parseFloat(this.book[0].style.getPropertyValue("--scrollLeft").replace("px", "")),
            top: parseFloat(this.book[0].style.getPropertyValue("--scrollTop").replace("px", ""))
        }
    }

    setMovePosition(x, y, returnProperties = false) {
        let properties = {
            "--scrollLeft": `${x}px`,
            "--scrollTop": `${y}px`
        };
        if (returnProperties) {
            return properties;
        } else {
            this.setProperties(this.book, properties);
        }
    }

    bookPointToViewer(point) {
        return {
            x: point.x + this.book.position().left,
            y: point.y + this.book.position().top
        }
    }

    viewerPointToBook(point) {
        return {
            x: point.x - this.book.position().left,
            y: point.y - this.book.position().top
        }
    }

    clearPoints() {
        this.viewer.find(".point").remove();
    }

    addPoint(x, y, name="", color = "#ff8800") {
        let index = this.viewer.find(".point").length;
        this.viewer.append(`
            <div title="${name}" class="point" style="--color: ${color}; --x: ${x}px; --y: ${y}px; --index: ${index}"></div>
        `)
    }

    zoomTo(zoomLevel, fixPoint = null, moveFixPointToCenter = false) {
        if (fixPoint === null) {
            this.zoom = zoomLevel;
            this.updateZoom();
            return;
        }

        let zoomCenter = this.getBookCenterPoint();        
        let viewerFixPoint = fixPoint;

        let fixPointZoomOffsetPreZoom = {
            x: viewerFixPoint.x - zoomCenter.x,
            y: viewerFixPoint.y - zoomCenter.y,
        };

        let zoomFactor = zoomLevel / this.zoom;
        let moveFactor = zoomFactor - 1;

        let postZoomOffset = {
            x: fixPointZoomOffsetPreZoom.x * moveFactor,
            y: fixPointZoomOffsetPreZoom.y * moveFactor
        }
        let postZoomFixPoint = {
            x: viewerFixPoint.x * zoomFactor,
            y: viewerFixPoint.y * zoomFactor
        };
        this.zoom = zoomLevel;
        let properties = this.updateZoom(true);
        if (moveFixPointToCenter) {
            properties = Object.assign(properties, this.moveTo(postZoomFixPoint.x, postZoomFixPoint.y, true));
        } else {
            properties = Object.assign(properties, this.moveBy(postZoomOffset.x, postZoomOffset.y, true));
        }
        this.setProperties(this.book, properties);
    }

    moveBy(offsetX, offsetY, returnProperties = false) {
        let position = this.getMovePosition();
        return this.setMovePosition(position.left + offsetX, position.top + offsetY, returnProperties);
    }
1
    moveTo(centerX, centerY, returnProperties = false) {
        return this.setMovePosition(centerX - this.book.width() / 2, centerY - this.book.height() / 2, returnProperties);
    }

    updateZoom(returnProperties = false) {
        let scale = this.zoom / this.overScale;
        let properties = {
            "--scale": `${scale}`,
            "--overscale": `${this.overScale}`
        }
        if (returnProperties) {
            return properties;
        } else {
            this.setProperties(this.book, properties);
        }
    }

    normalizeEventLocationBook(event) {
        return this.normalizeEventLocation(event, this.book);
    }

    normalizeEventLocationViewer(event) {
        return this.normalizeEventLocation(event, this.viewer);
    }

    normalizeLocation(element, location) {
        let elementX = element.offset().left;
        let elementY = element.offset().top;
        return {
            x: location.x - elementX + $(window).scrollLeft(),
            y: location.y - elementY + $(window).scrollTop(),
        };
    }

    normalizeEventLocation(event, element) {
        let windowX = event.clientX;
        let windowY = event.clientY;
        let elementX = element.offset().left;
        let elementY = element.offset().top;
        return {
            x: windowX - elementX + $(window).scrollLeft(),
            y: windowY - elementY + $(window).scrollTop(),
        };
    }

    setProperties(element, properties) {
        let propertyString = "; ";
        for (let propertyName in properties) {
            propertyString += `${propertyName}: ${properties[propertyName]}; `;
        }
        $(element)[0].style.cssText += propertyString;
    }

    async show() {
        // Prepare viewer
        $("body").append(`
            <div class="flipbook-viewer-overlay" id="flipbook-overlay-${this.viewerId}">
                <div class="flipbook-controls" id="flipbook-controls-${this.viewerId}">
                    <i class="fa-solid fa-circle-xmark flipbook-close"></i>
                    <span style="margin-right: 1rem;"></span>
                    <i class="fa-solid fa-magnifying-glass-minus flipbook-zoom-out"></i>
                    <i class="fa-solid fa-magnifying-glass-plus flipbook-zoom-in"></i>
                    <span style="margin-right: 1rem;"></span>
                    <i class="fa-solid fa-circle-chevron-left flipbook-prev"></i>
                    <i class="fa-solid fa-circle-chevron-right flipbook-next"></i>
                </div>
                <div class="flipbook-viewer" id="flipbook-viewer-${this.viewerId}">
                    <div class="flipbook" style="display: none;">
                    
                    </div>
                </div>
                <div class="flipbook-loader" id="flipbook-loader-${this.viewerId}">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Wird geladen...</span>
                    </div>
                    <div class="error-icon" role="status">
                        <i class="fa-solid fa-warning"></i>
                    </div>
                    <span class="message">Wird geladen...</span>
                </div>
            </div>
        `);            

        let that = this;
        
        // Get DOM Elements
        this.overlay = $(`#flipbook-overlay-${this.viewerId}`);
        this.viewer = $(`#flipbook-viewer-${this.viewerId}`);
        this.controls = $(`#flipbook-controls-${this.viewerId}`);
        this.book = this.viewer.find(".flipbook");        
        this.loader = $(`#flipbook-loader-${this.viewerId}`);        
        this.loader.show();
        
        // Add event listeners
        this.controls.on("click", ".flipbook-close", function() {
            this.close();
        }.bind(this));
        this.controls.on("click", ".flipbook-prev", function() {
            this.prevPage();
        }.bind(this));
        this.controls.on("click", ".flipbook-next", function() {
            this.nextPage();
        }.bind(this));
        this.controls.on("click", ".flipbook-zoom-out", function() {
            if (this.zoom > 0.5) {
                this.zoomTo(this.zoom - 0.5, this.getViewerCenterPoint());
            }
        }.bind(this));
        this.controls.on("click", ".flipbook-zoom-in", function() {            
            if (this.zoom < this.overScale) {
                this.zoomTo(this.zoom + 0.5, this.getViewerCenterPoint());
            }
        }.bind(this));
        $("body").on("keydown", this.keydown);

        this.viewer.on("mousewheel", ".flipbook", async function(event) {
            let cursorPosition = that.normalizeEventLocationViewer(event);
            let newZoom = 1;            
                        
            if (event.originalEvent.deltaY > 0) {
                newZoom = Math.max(that.zoom * 0.9, 0.5);
            } else if (event.originalEvent.deltaY < 0) {
                newZoom = Math.min(that.zoom / 0.9, that.overScale);
            }
            if (newZoom === that.zoom) {
                return;
            }
            that.zoomTo(newZoom, cursorPosition);
        });

        this.hammer = new Hammer.Manager(this.viewer[0]);
        this.hammer.add(new Hammer.Pan({direction: Hammer.DIRECTION_ALL, threshold: 0}));
        this.hammer.add(new Hammer.Pinch({threshold: 0.05}));
        let doubleTap = new Hammer.Tap({event: "doubletap", taps: 2});
        let singleTap = new Hammer.Tap({event: "singletap", taps: 1});
        this.hammer.add(doubleTap);
        this.hammer.add(singleTap);
        doubleTap.recognizeWith(singleTap);
        singleTap.requireFailure(doubleTap);
        doubleTap.dropRequireFailure(singleTap);
        this.hammer.on("panstart panmove panend", this.handlePan.bind(this));
        this.hammer.on("pinchstart pinchmove pinchend", this.handlePinch.bind(this));
        this.hammer.on("doubletap", this.handleDoubleTap.bind(this));
        this.hammer.on("singletap", this.handleSingleTap.bind(this));
                
        // Load FlipBook info
        this.showLoadingInfo("Meta-Informationen werden geladen");
        this.flipbook = await this.getFlipBook();
        if (!this.flipbook) {
            this.showError("Kein aktives Dokument vorhanden");
            return;
        }
        // Load PDF
        this.showLoadingInfo("Dokument wird geladen");
        let pdfUrl = this.flipbook.pdf.absoluteDownload;
        this.pdf = await this.loadPdf(pdfUrl);

        // Create papers and calculate ratio
        await this.createPapers();
        // Calculate paper and book sizes
        this.calculateSize();        
        // Set canvas to size 
        this.updateSize();

        // Add paper classes
        this.numPapers = this.book.find(".flipbook-paper").length;
        this.book.find(".flipbook-paper").each(function() {
            $(this).css("z-index", $(this).data("paperid") === 1 ? 2 : 1);
        });
        this.book.addClass("frontcover");
        
        // Render pages and show flipbook
        await this.render();
        this.showPaper(1);
        this.scrollToCenter();
    }

    showError(message) {
        this.loader.find(".spinner-border").hide();
        this.loader.find(".error-icon").show();
        this.loader.find(".message").addClass("error");
        this.loader.find(".message").text(message);
    }

    showLoadingInfo(info) {
        this.loader.find(".spinner-border").show();
        this.loader.find(".error-icon").hide();
        this.loader.find(".message").removeClass("error");
        this.loader.find(".message").html(info);
    }

    onKeyDown(event) {
        switch (event.key) {
            case "ArrowLeft":
                this.prevPage();
                break;
            case "ArrowRight":
                this.nextPage();
                break;
            case "Escape":
                this.close();
                break;
        }
    }

    handleSingleTap(ev) {
        let tapLocation = this.normalizeLocation(this.viewer, ev.center);
        let bookPosition = this.viewerPointToBook(tapLocation);
        let bookWidth = this.book.width();

        let lowerBarrier = 0;
        let upperBarrier = 1;
        let threshold = 0.15;

        if (!this.isOpen()) {
            lowerBarrier = 0.25;
            upperBarrier = 0.75;
        }
        let bookPercentage = bookPosition.x / bookWidth;

        if (bookPercentage > upperBarrier || bookPercentage < lowerBarrier) {
            return;
        }
        if (bookPercentage < lowerBarrier + threshold) {
            this.prevPage();
        } else if (bookPercentage > upperBarrier - threshold) {
            this.nextPage();
        }
    }

    handleDoubleTap(ev) {        
        let calculatedCenter = this.normalizeLocation(this.viewer, ev.center);
        if (this.zoom < 2) {
            this.zoomTo(2, calculatedCenter);
        } else if (this.zoom < 2) {
            this.zoomTo(2, calculatedCenter);
        } else if (this.zoom < this.overScale) {            
            this.zoomTo(this.overScale, calculatedCenter);
        } else {
            this.zoomTo(1, calculatedCenter);
        }
    }

    handlePan(ev) {
        this.handlePanPinch(ev);
    }

    handlePinch(ev) {
        this.handlePanPinch(ev);
    }

    handlePanPinch(ev) {
        let calculatedCenter = this.normalizeLocation(this.viewer, ev.center);
        switch (ev.type) {
            case "pinchstart":
            case "panstart":
                this.pinchData.startZoom = this.zoom;
                this.pinchData.lastDeltaX = 0;
                this.pinchData.lastDeltaY = 0;
                this.pinchData.lastDistance = 0;
                this.book.addClass("pinching");
                break;
            case "panmove":
            case "panend":
            case "pinchmove":
            case "pinchend":
                let isEnd = ev.type === "pinchend" || ev.type === "panend";

                let zoomLevel = this.pinchData.startZoom * ev.scale;
                let zoomChange = zoomLevel / this.zoom;
                zoomLevel = Math.min(zoomLevel, this.overScale);
                zoomLevel = Math.max(zoomLevel, 0.5);
                let zoomDiff = 0.03;

                let distanceDiff = 2;
                let distance = Math.abs(this.pinchData.lastDistance - ev.distance);

                if (!isEnd && (zoomChange < 1 + zoomDiff && zoomChange > 1 - zoomDiff) && distance < distanceDiff) {
                    return;
                }

                let deltaX = ev.deltaX - this.pinchData.lastDeltaX;
                let deltaY = ev.deltaY - this.pinchData.lastDeltaY;

                this.pinchData.lastDeltaX = ev.deltaX;
                this.pinchData.lastDeltaY = ev.deltaY;
                this.pinchData.lastDistance = ev.distance;

                if (ev.type.includes("pinch")) {
                    this.zoomTo(zoomLevel, calculatedCenter);
                }
                this.moveBy(-deltaX, -deltaY);

                if (isEnd) {
                    this.book.removeClass("pinching");
                }
                break;
        }
    }

    close() {
        $(`#flipbook-overlay-${this.viewerId}`).remove();
        $("body").off("keydown", this.keydown);
    }

    copy(o) {
        return JSON.parse(JSON.stringify(o));
    }

    async createPapers() {
        let currentPage = 1;
        for (let pageId = 1; pageId <= this.pdf.numPages; pageId++) {
            currentPage = this.createCanvas(pageId, currentPage);
        }
    }

    getExistingCanvasPages() {
        let pages = [];
        $(this.book).find("canvas").each(function() {
            pages.push($(this).data("pagenum"));
        });
        return pages;
    }

    showPaper(targetPaperNum) {
        let tolerance = 2;
        let pages = this.getExistingCanvasPages();
        for (let canvasId in this.canvases) {
            let pageNum = parseInt(canvasId);
            let paperId = this.getPaperId(pageNum);
            let canvas = this.canvases[pageNum];
            console.log(paperId);
            console.log(targetPaperNum);
            let shouldBeVisible = targetPaperNum - tolerance <= paperId && targetPaperNum + tolerance >= paperId;
            if (pages.includes(pageNum)) {
                console.log("Hiding page " + pageNum);
                if (!shouldBeVisible) {
                    console.log("Detaching page " + pageNum);
                    canvas.detach();
                }
            } else {
                console.log("Showing page " + pageNum);
                if (shouldBeVisible) {
                    console.log("Attaching page " + pageNum);
                    this.book.find(`.paper-content[data-pagenum="${pageNum}"]`).append(canvas);
                }
            }
        }
    }

    openFlipBook() {
        this.book.removeClass("frontcover");
        this.book.removeClass("backcover");
        this.book.addClass("open");
    }

    closeFlipBook() {
        this.book.removeClass("open");
        this.book.removeClass("frontcover");
        this.book.removeClass("backcover");
        this.book.removeClass("left");
        this.book.removeClass("right");
        if (this.currentPaper > 2) {
            this.book.addClass("backcover");
        } else {
            this.book.addClass("frontcover");
        }
    }

    adjustZIndex(paperId, direction = "forwards") {
        let currentPapers = {};
        if (direction === "forwards") {
            currentPapers[paperId - 1] = 3;
            currentPapers[paperId] = 4;
            currentPapers[paperId + 1] = 3;
        } else {
            currentPapers[paperId - 1] = 3;
            currentPapers[paperId] = 4;
            currentPapers[paperId + 1] = 3;
        }
            
        this.book.find(".flipbook-paper").each(function() {
            let id = $(this).data("paperid");
            if (id in currentPapers) {            
                $(this).css("z-index", currentPapers[id]);
            } else {
                $(this).css("z-index", 1);
            }
        });
    }

    nextPage() {
        if (this.isAtBackCover()) {
            return;
        }

        if (this.isClosed()) {
            this.openFlipBook();
        } else if (!this.isAtRightEdge()) {
            // Scroll to right if not already there
            this.scrollToRight();
            return;
        }        
        
        if (this.currentPaper <= this.numPapers) {
            this.adjustZIndex(this.currentPaper, "forwards");
            let currentPaperDiv = this.getPaperDivByPaperId(this.currentPaper);
            currentPaperDiv.addClass("flipped");

            if (this.currentPaper === this.numPapers) {
                this.closeFlipBook();
                this.scrollToCenter();
            } else {
                this.scrollToLeft();
            }
            this.currentPaper++;
            this.showPaper(this.currentPaper);
        }
        
    }

    prevPage() {
        if (this.isAtFrontCover()) {
            return;
        }

        if (this.isOpen() && !this.isAtLeftEdge()) {
            this.scrollToLeft();
            return;
        }

        if (this.currentPaper > 1) {
            this.adjustZIndex(this.currentPaper - 1, "backwards");
            let previousPaperDiv = this.getPaperDivByPaperId(this.currentPaper - 1);
            previousPaperDiv.removeClass("flipped");   
                       
            this.book.removeClass("left");
            this.book.addClass("right");
            
            if (this.currentPaper > this.numPapers) {
                this.openFlipBook();
            }

            if (this.currentPaper === 2) {
                this.closeFlipBook();                
                this.scrollToCenter();
            } else {                
                this.scrollToRight();
            }
            this.currentPaper--;
            this.showPaper(this.currentPaper);
        }
    }

    isAtFrontCover() {
        return this.book.hasClass("frontcover");
    }

    isAtBackCover() {
        return this.book.hasClass("backcover");
    }

    isOpen() {
        return this.book.hasClass("open");
    }

    isClosed() {
        return !this.isOpen();;
    }

    isAtRightEdge() {
        let width = this.book.width();
        let left = this.book.position().left;
        let right = width + left;
        return right <= this.viewer.width() + 1;
    }
    
    isAtLeftEdge() {
        return this.book.position().left >= -1;        
    }

    getOverWidth(asHalf = false, limit = false) {
        let viewerWidth = this.viewer.width();
        let bookWidth = this.book.width();
        let overWidth = bookWidth - viewerWidth;
        if (asHalf) {
            overWidth = overWidth / 2;
        }
        if (limit && overWidth < 0) {
            overWidth = 0;
        }
        return overWidth;
    }

    getOverHeight(asHalf = false, limit = false) {
        let viewerHeight = this.viewer.height();
        let bookHeight = this.book.height();
        let overHeight = bookHeight - viewerHeight;
        if (asHalf) {
            overHeight = overHeight / 2;
        }
        if (limit && overHeight < 0) {
            overHeight = 0;
        }
        return overHeight;
    }

    scrollToCenter() {
        let center = this.viewer[0].scrollWidth / 2 - this.viewer[0].offsetWidth / 2;
        let scrollTop = this.getOverHeight(true, true);
        this.setMovePosition(0, -scrollTop);
    }

    scrollToLeft() {
        let scrollLeft = this.getOverWidth(true, true);
        let scrollTop = this.getOverHeight(true, true);
        this.setMovePosition(-scrollLeft, -scrollTop);
        return;
    }

    scrollToRight() {    
        let scrollLeft = this.getOverWidth(true, true);
        let scrollTop = this.getOverHeight(true, true);
        this.setMovePosition(scrollLeft, -scrollTop);
    }

    createPaperSite(pageId, pageNum, isDoublePage, side) {
        let paper = this.getPaperDiv(pageNum);
        let canvas = $(`<canvas class="flipbook-canvas" data-isdouble="${isDoublePage}" data-pageid="${pageId}" data-pagenum="${pageNum}"></canvas>`);
        let wrapper = $(`<div class="${side}"></div>`);
        this.canvases[pageNum] = canvas;
        let contentWrapper = $(`<div class="${side}-content paper-content" data-pagenum="${pageNum}"></div>`);        
        paper.append(wrapper);
        wrapper.append(contentWrapper);
        // contentWrapper.append(canvas);
    }

    createCanvas(pageId, firstPageNum) {
        let page = this.pages[pageId] ?? null;
        if (page === null) {
            console.error("Page is unexpectedly null");
            return firstPageNum;
        }
        
        let isDoublePage = this.pageInfo[pageId]["isDoublePage"];
        let leftClass = firstPageNum % 2 == 0 ? "back" : "front";
        let rightClass = firstPageNum % 2 == 1 ? "back" : "front";

        this.pageNumbers[firstPageNum] = pageId;
        this.createPaperSite(pageId, firstPageNum, isDoublePage, leftClass);
        
        if (isDoublePage) {
            this.pageNumbers[firstPageNum + 1] = pageId;
            this.createPaperSite(pageId, firstPageNum + 1, isDoublePage, rightClass);
            return firstPageNum + 2;
        }
        return firstPageNum + 1;
    }
    
    async render(loadingIndicator = true) {   
        this.showLoadingInfo("Anzeige wird vorbereitet");     
        for (let renderTask of this.renderTasks) {
            renderTask.cancel();
        }
        this.renderTasks = [];

        if (loadingIndicator) {
            this.book.hide();
            this.loader.show();
        }
        let promises = [];
        let numPages = this.maxPages;
        for (let pageId in this.pages) {
            let promise = this.renderPage(pageId);
            this.showLoadingInfo(`Anzeige wird vorbereitet<br />Seite ${pageId} / ${numPages}`);     
            promises.push(promise);
            await promise;
        }
        for (let i in promises) {
            let promise = promises[i];
            try {
                await promise;
            } catch (e) {
                let name = e.name ?? null;
                if (name !== "RenderingCancelledException") {
                    console.warn(e);
                }
            }
        }
        this.loader.hide();
        this.book.show();
    }

    async renderPage(pageId) {
        let page = this.pages[pageId];
        let pageInfo = this.pageInfo[pageId];
        if (pageInfo["isDoublePage"]) {
            let pageNumberLeft = pageInfo["pageNumberLeft"];
            let pageNumberRight = pageInfo["pageNumberRight"];
            let canvasLeft = this.canvases[pageNumberLeft];
            let canvasRight = this.canvases[pageNumberRight];
            await this.renderSinglePage(page, canvasLeft, "left");
            await this.renderSinglePage(page, canvasRight, "right");
        } else {
            let pageNumber = pageInfo["pageNumber"];
            let canvas = this.canvases[pageNumber];
            await this.renderSinglePage(page, canvas, "full");
        }
    }

    async renderSinglePage(page, canvas, section="full") {
        let isDoublePage = canvas.data("isdouble");
        let viewport = this.copy(page.getViewport({"scale": 1}));
        if (isDoublePage) {
            viewport.width /= 2;
        }
        canvas = canvas[0];
        let scaleX = this.overScale * this.pageWidth / viewport.width;        
        let scaleY = this.overScale * this.pageHeight / viewport.height;        
        let scale = Math.min(scaleX, scaleY);
        viewport = this.copy(page.getViewport({"scale": scale}));
        if (isDoublePage) {
            if (section === "right") {                
                viewport = this.copy(page.getViewport({"scale": scale, "offsetX": -viewport.width / 2}));                
            }
            viewport.width /= 2;
        }
        
        let outputScale = window.devicePixelRatio || 1;
        let context = canvas.getContext("2d");
        let transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;
        let renderContext = {
            canvasContext: context,
            transform: transform,
            viewport: viewport
        };
        
        let renderTask = page.render(renderContext);
        this.renderTasks.push(renderTask);
        await renderTask.promise;
    }

    async loadFlipBook() {
        let request = null;
        if (this.flipBookId === false) {
            request = $.ajax({
                "url": "api/flipbook/getactive"
            });
        } else {
            request = $.ajax({
                "url": "api/flipbook/get",
                "data": {
                    "id": this.flipBookId
                }
            });
        }

        try {
            let result = await request;
            return result.flipbook;
        } catch (e) {
            console.error(e);
            return false;
        }
    }
}

$(function() {
    $("body").on("click", ".flipbook-trigger", function() {
        let flipBookId = $(this).data("flipbook") || false;
        let viewer = new FlipBookViewer(flipBookId);
        viewer.show();
    });
})