class FlipBookAdmin {
    constructor() {
        this.flipbookBrowser = null;
        this.init();
    }

    init() {   
        let body = $("body");
        let that = this;

        body.on("click", "#flipbook_select_pdf", function() {
            var dlg = new Dialog();
            dlg.fileBrowser(
                "PDF wählen",
                "Wählen Sie ein PDF-Dokument aus",
                "documents",
                [],
                true,
                function(doc) {
                    dlg.close();                
                    let name = $("#flipbook_name").val();
                    that.uploadFlipbook(doc, name);
                }, 
                function() {
                    dlg.close();
                },
                {
                    extensions: [],
                    mimes: ["application/pdf"],
                    foldercreation: true,
                    upload: true
                }            
            );
        });

    
        body.on("click", "#flipbook_reset_active", function() {
            that.activateFlipbook(-1);
        });
        body.on("click", "#flipbook_preview_active", function() {
            that.showActiveFlipbook();
        });
        
        // Show browser for existing flipbooks
        this.flipbookBrowser = new DialogFileBrowser("flipbook", "flipbook_existing", "flipbook_existing", function(file) {
            that.showFlipbookPopup(file);
        });
        this.flipbookBrowser.selectFolder = true;
        this.flipbookBrowser.selectFile = false;
        this.flipbookBrowser.navigate(-1);        

        this.updateActiveFlipbook();
    };


    async uploadFlipbook(doc, name) {
        var dlg = new Dialog();
        
        dlg.loading("Bitte warten", 'FlipBook <span class="blue-text">' + name + '</span> wird aus <span class="blue-text">'+doc.name+'</span> erstellt...');
        try {
            let result = await $.ajax({
                url: "api/flipbook/import",
                type: "POST",
                data: {
                    document: doc.id,
                    name: name
                }
            });
            console.log(result);            
            dlg.close();
            new Notification().success("Konvertierung erfolgreich");        
            this.refreshFlipbookList();
            this.updateActiveFlipbook();
        } catch (e) {
            console.error(e);
            dlg.close();
            new Notification().error("Konvertierung fehlgeschlagen");
            this.refreshFlipbookList();
            this.updateActiveFlipbook();
        }
    }

    refreshFlipbookList() {
        this.flipbookBrowser.navigate(-1);
    }

    showActiveFlipbook() {
        let flipBookViewer = new FlipBookViewer();
        flipBookViewer.show();
    }

    showFlipbookPopup(folder) {
        let dlg = new Dialog();
        let that = this;
        dlg.build()
            .addTitle('FlipBook <b>'+folder.name+'</b>')
            .addHtml("Wählen Sie aus einer der folgenden Optionen")
            .addButton("Vorschau", "show_flipbook", function() {
                dlg.close();
                let flipBookViewer = new FlipBookViewer(folder.id);
                flipBookViewer.show();
                //that.showFlipbookId(folder.id);
            })
            .addButton("Dateien", "files_flipbook", function() {
                dlg.close();   
                that.flipbookBrowser.navigate(folder.id);
            })
            .addButton("Als Aktiv markieren", "activate_flipbook", function() {
                dlg.close();
                that.activateFlipbook(folder.id);
            })
            .addButton("Löschen", "delete_flipbook", function() {
                dlg.close();
                dlg = new Dialog();
                dlg.loading("Bitte warten", "FlipBook wird gelöscht...");
                that.flipbookBrowser.removeFolder(folder.id, function() {
                    dlg.close();
                    that.flipbookBrowser.navigate(-1);
                });         
            })
            .addButton("Schließen", "close_flipbook", function() {
                dlg.close();
            })
            .onEscape(function() {
                dlg.close();
            })
            .show();
    }

    updateActiveFlipbook() {
        $.ajax({
            url: "api/flipbook/getactive",
            type: "POST",
            success: function(resp) {
                if (!resp.flipbook) {
                    $("#active_flipbook_info").html(`                    
                        <div class="col s12 l8">
                            <h5 id="flipbook_active_title">Keine aktive PDF-Anzeige</h5>
                        </div>
                    `);
                } else {
                    let flipbook = resp.flipbook;
                    $("#active_flipbook_info").html(`
                        <div class="col s12 l4">
                            <img src="${flipbook.coverS.download}" id="flipbook_active_preview" style="margin-right: 30px;" />
                        </div>
                        <div class="col s12 l8" id="flipbook_active_info">
                            <h5 id="flipbook_active_title">${flipbook.name}</h5>
                            ${flipbook.pageCount} Seiten<br />
                            <br />
                            <button class="btn waves-effect blue" id="flipbook_reset_active">
                                <i class="material-icons left">undo</i>
                                Deaktivieren
                            </button>
                            <button class="btn waves-effect blue" id="flipbook_preview_active">
                                <i class="material-icons left">menu_book</i>
                                Vorschau
                            </button>
                        </div>
                    `);
                }
            },
            error: function() {
                new Notification().error("FlipBook konnte nicht aktualisiert werden");
            }
        });
    }

    activateFlipbook(id) {
        let that = this;
        $.ajax({
            url: "api/admin/savesetting",
            type: "POST",
            data: {
                key: "plugins/flipbook/activeid",
                value: id
            },
            success: function() {
                that.updateActiveFlipbook();
            },
            error: function() {
                new Notification().error("Einstellung konnte nicht gespeichert werden");
            }
        });
    }
}
